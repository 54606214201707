import { Injectable } from '@angular/core';
import { CanActivate } from '@angular/router';

import { ClientConfigService } from '@frontend/vanilla/core';

import { TargetPromotionDetailConfiguration } from '../target-promotion-detail-config';

@Injectable()
export class LeaderBoardRouteGuard implements CanActivate {
    constructor(
        private clientConfigService: ClientConfigService,
        private targetPromotionDetailConfig: TargetPromotionDetailConfiguration,
    ) {}

    async canActivate() {
        if (!this.targetPromotionDetailConfig.isConfigReady) await this.clientConfigService.load('', [TargetPromotionDetailConfiguration]);
        return true;
    }
}
