import { PreRequisite } from '../../common/models/pre-requisite/pre-requisite';
import { PreRequisiteProgress } from '../../common/models/pre-requisite/pre-requisite-progress';
import { MyProgress } from './my-progress';

export class PlayerActivityDetailsStoreModel {
    playerActivityContent: PlayerActivityContentStoreModel;
    playerActivityMetaData: PlayerActivityMetaDataStoreModel;
}

export interface PlayerActivityContentStoreModel {
    myProgress: MyProgress;
    preRequisite: PreRequisite;
    totalWagered: PreRequisiteProgress;
}

export interface PlayerActivityMetaDataStoreModel {
    playerPromoConfiguration: PlayerPromoConfiguration;
}

export interface PlayerPromoConfiguration {
    criteria: Criteria;
    minCriteria: MinCriteria;
    awardSchedule: string;
}

export interface Criteria {
    criteriaType: string;
    slabType: string;
    slabDetails: any;
}

export interface MinCriteria {
    minCriteriaType: string;
    minCriteriaQualified: boolean;
}
