import { Injectable } from '@angular/core';

import { TrackingModelService } from '@frontend/promo-homewidget';
import { ToastrQueueService } from '@frontend/vanilla/core';
import { Actions, EffectNotification, OnRunEffects, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { EMPTY, Observable, of } from 'rxjs';
import { catchError, exhaustMap, map, mergeMap, takeUntil } from 'rxjs/operators';

import { MyCardsContent, MyCardsStateModel } from '../../common/models/my-cards/my-cards-state-models';
import { LeaderboardRankingStoreModel, NextRewardDetails, PlayerRankDetails } from '../../common/models/ranking/ranking-models';
import { getRevealCardData, loadMyCardsData, userRevealedMyCard_Success } from '../../common/my-cards/store/my-cards.actions';
import {
    getLeaderboardRankingScrollDownData,
    getLeaderboardRankingScrollUpData,
    loadLeaderBoardRankingData_Success,
    loadLeaderboardRankingScrollDownData,
    loadLeaderboardRankingScrollUpData,
} from '../../common/ranking-common/store/leaderboard-ranking.actions';
import { targetPromoMetaData } from '../../common/store/target-promotion.selectors';
import {
    loadTargetPromotionData_Fail,
    loadTargetPromotionData_Success,
    updateResultsContent,
    updateTabs,
    userOptInFailedForPromotion,
    userOptInInvalidPromoIdForPromotion,
    userOptInSuccessForPromotion,
    userOptedInForPromotion,
} from '../../common/store/target-promotions.actions';
import { CardRushContent } from '../card-rush-models/card-rush-content';
import { CardRushDetails, CardRushRequest } from '../card-rush-models/card-rush-details';
import { CardRushMetaData } from '../card-rush-models/card-rush-metadata';
import { CardRushService } from '../card-rush-service/card-rush.service';
import {
    cardRushDestroyed,
    cardRushInit,
    cardRushReveal,
    getCardRushData,
    loadCardRushState,
    loadCardRushSuccess,
    loadLeaderBoardState,
    optInSuccess,
} from './card-rush.actions';

@Injectable()
export class CardRushEffects implements OnRunEffects {
    loadCardRushData$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(getCardRushData.type),
            mergeMap((request: CardRushRequest) => {
                return this.cardRushService.getCardRushDetails(request).pipe(
                    map((response: CardRushDetails) => loadCardRushSuccess(response)),
                    catchError(() => {
                        if (request?.isAutoOptin) {
                            userOptInInvalidPromoIdForPromotion();
                            this.trackingModelService.submitTracking({
                                CategoryEvent: 'promo hub',
                                LabelEvent: 'opt in',
                                ActionEvent: 'error',
                                PositionEvent: request.promoId,
                                EventDetails: 'promotion either expired/invalid',
                                LocationEvent: 'offer page',
                            });
                        }
                        return of(loadTargetPromotionData_Fail());
                    }),
                );
            }),
        );
    });

    loadLeaderBoardRankingData_Success$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(loadCardRushSuccess, cardRushReveal),
            map((response) =>
                loadLeaderBoardRankingData_Success(this.getLeaderboardRankingStateModel(response.cardRushContent, response.cardRushMetaData)),
            ),
        );
    });

    loadMyCardsData$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(loadCardRushSuccess, optInSuccess),
            map((response) => loadMyCardsData(this.getMyCardsStateModel(response))),
        );
    });

    loadCardRushState$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(loadCardRushSuccess, optInSuccess),
            map((response) => loadCardRushState(response.cardRushContent?.myCardsDetails)),
        );
    });

    loadLeaderBoardState$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(loadCardRushSuccess),
            map((response) =>
                loadLeaderBoardState({
                    leaderboardResultsContent: response.cardRushContent.leaderboardResultsContent,
                    leaderboardRewardsContent: response.cardRushContent.leaderboardRewardsContent,
                }),
            ),
        );
    });

    loadTargetPromotionData_Success$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(loadCardRushSuccess),
            map((response) =>
                loadTargetPromotionData_Success({
                    metadata: response.cardRushMetaData,
                    content: response.cardRushContent,
                }),
            ),
        );
    });

    loadOptInCardRushData$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(userOptedInForPromotion.type),
            map((action: { promoId: string }) => action.promoId),
            mergeMap((promoId) =>
                this.cardRushService.optedIn(promoId).pipe(
                    map((response: CardRushDetails) => optInSuccess(response)),
                    catchError(() => {
                        of(userOptInFailedForPromotion());
                        return EMPTY;
                    }),
                ),
            ),
        );
    });

    userOptInSuccessForPromotion$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(optInSuccess),
            map((response) =>
                userOptInSuccessForPromotion({
                    optinDetails: response.cardRushMetaData.optinDetails,
                    optinContent: response.cardRushContent.optinContent,
                }),
            ),
        );
    });

    updateTabs$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(optInSuccess, cardRushReveal),
            map((response) => updateTabs({ tabs: response.cardRushContent.tabs })),
        );
    });

    updateResultsContent$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(optInSuccess, cardRushReveal),
            map((response) => updateResultsContent({ resultsContent: response.cardRushContent.resultsContent })),
        );
    });

    loadCardRushScrollUpRankingData$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(getLeaderboardRankingScrollUpData.type),
            map((request: { leaderBoardRequest: CardRushRequest; showSpinner: boolean }) => request),
            concatLatestFrom(() => this.store.select(targetPromoMetaData)),
            mergeMap(([request, targetPromoMetaData]) => {
                request.leaderBoardRequest.cmsItemIdentifier = targetPromoMetaData.cmsItemIdentifier;

                return this.cardRushService.getCardRushRankingDetails(request.leaderBoardRequest, request.showSpinner).pipe(
                    map((leaderboardDetails: PlayerRankDetails[]) =>
                        loadLeaderboardRankingScrollUpData({
                            leaderboardDetails: leaderboardDetails,
                        }),
                    ),
                    catchError(() => of(loadTargetPromotionData_Fail())),
                );
            }),
        );
    });

    loadCardRushScrollDownRankingData$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(getLeaderboardRankingScrollDownData.type),
            map((request: { leaderBoardRequest: CardRushRequest; showSpinner: boolean }) => request),
            concatLatestFrom(() => this.store.select(targetPromoMetaData)),
            mergeMap(([request, targetPromoMetaData]) => {
                request.leaderBoardRequest.cmsItemIdentifier = targetPromoMetaData.cmsItemIdentifier;

                return this.cardRushService.getCardRushRankingDetails(request.leaderBoardRequest, request.showSpinner).pipe(
                    map((leaderboardDetails: PlayerRankDetails[]) =>
                        loadLeaderboardRankingScrollDownData({
                            leaderboardDetails: leaderboardDetails,
                        }),
                    ),
                    catchError(() => of(loadTargetPromotionData_Fail())),
                );
            }),
        );
    });

    loadCardRushRevealCardData$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(getRevealCardData.type),
            mergeMap((request: CardRushRequest) =>
                this.cardRushService.getCardRushRevealCardPrize(request).pipe(
                    map((response: CardRushDetails) => cardRushReveal(response)),
                    catchError(() => {
                        this.toastrQueueService.add('RevealCardError');
                        return EMPTY;
                    }),
                ),
            ),
        );
    });

    userRevealedMyCard_Success$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(cardRushReveal),
            map((response) => userRevealedMyCard_Success(this.getRevealMyCardsStateModel(response))),
        );
    });

    ngrxOnRunEffects(resolvedEffects$: Observable<EffectNotification>) {
        return this.actions$.pipe(
            ofType(cardRushInit.type),
            exhaustMap(() => resolvedEffects$.pipe(takeUntil(this.actions$.pipe(ofType(cardRushDestroyed.type))))),
        );
    }

    constructor(
        private actions$: Actions,
        private toastrQueueService: ToastrQueueService,
        private cardRushService: CardRushService,
        private store: Store,
        private trackingModelService: TrackingModelService,
    ) {}

    private getLeaderboardRankingStateModel(cardRushContent: CardRushContent, cardRushMetaData: CardRushMetaData): LeaderboardRankingStoreModel {
        return {
            leaderboardContent: {
                rankingImageContent: cardRushContent.rankingImageContent,
            },
            leaderboardMetaData: {
                leaderBoardDetails: cardRushMetaData.leaderboardDetails,
                currentPlayerRankDetails: {
                    rankDetails: cardRushMetaData.playerPromoConfiguration?.currentPlayerRankDetails,
                    nextRewardDetails: {} as NextRewardDetails,
                },
            },
        };
    }

    private getMyCardsStateModel(response: CardRushDetails): MyCardsStateModel {
        const myCardsStateModel = new MyCardsStateModel();
        const cardRushMetaData = response.cardRushMetaData;
        const cardRushContent = response.cardRushContent;
        myCardsStateModel.myCardsMetaData = {
            numberOfExpiredCards: cardRushMetaData.numberOfExpiredCards,
            numberOfUnClickedCards: cardRushMetaData.numberOfUnClickedCards,
        };
        myCardsStateModel.criteriaCardStatus = cardRushContent.criteriaCardStatus;
        myCardsStateModel.earnedCardResultDetails = cardRushContent.earnedCardResultDetails;

        const myCardsDetails = cardRushContent.myCardsDetails;
        if (myCardsDetails !== undefined) {
            myCardsStateModel.multiCardsImage = myCardsDetails.multiCardsImage;

            const criteriaCard = myCardsDetails.criteriaCard;
            const playerPromoConfiguration = response.cardRushMetaData.playerPromoConfiguration;
            if (playerPromoConfiguration !== undefined) {
                myCardsStateModel.myCardsContent = {
                    criteriaCard: {
                        completedCriteria: criteriaCard.completedCriteria,
                        criteriaCardDetails: criteriaCard.criteriaCardDetails,
                        criteriaCardsLength: playerPromoConfiguration.cardProgress?.noOfCardsLeftPerPlayer,
                    },
                    myEarnedCardsContent: myCardsDetails.myEarnedCardsContent,
                    earnedCardDetails: playerPromoConfiguration.earnedCardDetails,
                    earnedCardsImages: cardRushContent.cards,
                };
            }
        }

        return myCardsStateModel;
    }

    private getRevealMyCardsStateModel(response: CardRushDetails): MyCardsStateModel {
        const myCardsStateModel = new MyCardsStateModel();
        const cardRushMetaData = response.cardRushMetaData;
        const cardRushContent = response.cardRushContent;
        myCardsStateModel.myCardsMetaData = {
            numberOfExpiredCards: cardRushMetaData.numberOfExpiredCards,
            numberOfUnClickedCards: cardRushMetaData.numberOfUnClickedCards,
        };
        myCardsStateModel.earnedCardResultDetails = cardRushContent.earnedCardResultDetails;

        const playerPromoConfiguration = cardRushMetaData.playerPromoConfiguration;
        if (playerPromoConfiguration !== undefined) {
            myCardsStateModel.myCardsContent = {
                myEarnedCardsContent: cardRushContent.myCardsDetails?.myEarnedCardsContent,
                earnedCardDetails: playerPromoConfiguration.earnedCardDetails,
            } as MyCardsContent;
        }

        return myCardsStateModel;
    }
}
