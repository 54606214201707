import { Injectable } from '@angular/core';
import { CanActivate } from '@angular/router';

import { BetStationConfiguration } from '@frontend/promo-homewidget';
import { ClientConfigService } from '@frontend/vanilla/core';

@Injectable()
export class BetStationGuard implements CanActivate {
    constructor(
        private clientConfigService: ClientConfigService,
        private betStationConfiguration: BetStationConfiguration,
    ) {}

    async canActivate() {
        const configs = [] as any;
        if (!this.betStationConfiguration.isConfigReady) configs.push(BetStationConfiguration);
        if (configs.length > 0) await this.clientConfigService.load('', configs);
        return true;
    }
}
