import { Injectable } from '@angular/core';
import { CanActivate } from '@angular/router';

import { FilterConfiguration } from '@frontend/promo-homewidget';
import { ClientConfigService } from '@frontend/vanilla/core';

import { PromoFreeSpinHistoryConfiguration } from '../../client-configs/freespin-history-config';
import { PromoOffersHistoryConfiguration } from '../../client-configs/offers-history-v2-config';
import { OffersHistoryClientConfiguration } from './offer-history-config';

@Injectable()
export class OffersHistoryGuard implements CanActivate {
    constructor(
        private offersHistoryClientConfig: OffersHistoryClientConfiguration,
        private clientConfigService: ClientConfigService,
        private filterConfig: FilterConfiguration,
        private historyConfiguration: PromoFreeSpinHistoryConfiguration,
        private offersHistoryConfiguration: PromoOffersHistoryConfiguration,
    ) {}

    async canActivate() {
        const configs = [] as any;
        if (!this.offersHistoryClientConfig.isConfigReady) configs.push(OffersHistoryClientConfiguration);
        if (!this.filterConfig.isConfigReady) configs.push(FilterConfiguration);
        if (!this.historyConfiguration.isConfigReady) configs.push(PromoFreeSpinHistoryConfiguration);
        if (!this.offersHistoryConfiguration.isConfigReady) configs.push(PromoOffersHistoryConfiguration);
        if (configs.length > 0) await this.clientConfigService.load('', configs);
        return true;
    }
}
