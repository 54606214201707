import { Injectable } from '@angular/core';
import { CanActivate } from '@angular/router';

import { CommonService, FilterConfiguration, RegulatoryConfiguration } from '@frontend/promo-homewidget';
import { ClientConfigService } from 'packages/vanilla/lib/core/src/client-config/client-config.service';

import { MyGalaOffersConfiguration } from './offer-cards-config/offer-cards.config';

@Injectable({
    providedIn: 'root',
})
export class OfferCardsGuard implements CanActivate {
    constructor(
        private myGalaOffersConfiguration: MyGalaOffersConfiguration,
        private commonService: CommonService,
        private clientConfigService: ClientConfigService,
        private filterConfig: FilterConfiguration,
        private regulatoryConfig: RegulatoryConfiguration,
    ) {}

    async canActivate() {
        const configs = [] as any;
        if (!this.myGalaOffersConfiguration.isConfigReady) configs.push(MyGalaOffersConfiguration);
        if (!this.filterConfig.isConfigReady) configs.push(FilterConfiguration);
        if (!this.regulatoryConfig.isConfigReady) configs.push(RegulatoryConfiguration);

        if (configs.length > 0) await this.clientConfigService.load('', configs);

        if (this.myGalaOffersConfiguration.isMyGalaEnabled) {
            return true;
        } else {
            this.commonService.goToOverviewPage(false);
        }
        return false;
    }
}
