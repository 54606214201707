import { createReducer, on } from '@ngrx/store';

import { CardRushStateModel } from '../card-rush-models/card-rush-state-model';
import { cardRushDestroyed, loadCardRushState, loadLeaderBoardState } from './card-rush.actions';

export const initialState = new CardRushStateModel();

export const cardRushReducer = createReducer(
    initialState,
    on(loadCardRushState, (state, action) => ({
        ...state,
        cardRushCriteriaTitle: { ...action },
        leaderboardResultsContent: { ...state.leaderboardResultsContent },
        leaderboardRewardsContent: { ...state.leaderboardRewardsContent },
    })),
    on(loadLeaderBoardState, (state, action) => ({
        ...state,
        leaderboardResultsContent: { ...action.leaderboardResultsContent },
        leaderboardRewardsContent: { ...action.leaderboardRewardsContent },
    })),
    on(cardRushDestroyed, () => initialState),
);
