import { ResultsContent } from '../../common/models/results/results-content';
import { RewardContent } from '../../common/models/reward-content';

export class CardRushStateModel {
    cardRushCriteriaTitle: CardRushCriteriaTitle;
    leaderboardResultsContent: ResultsContent;
    leaderboardRewardsContent: RewardContent;
}

export class CardRushCriteriaTitle {
    numberOfCardsLeftForTheDayMessage: string;
    totalNumberOfCardsLeft: string;
    totalNumberOfCardsLeftMessage: string;
}
