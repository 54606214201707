import { Injectable } from '@angular/core';

import { TrackingModelService } from '@frontend/promo-homewidget';
import { Actions, EffectNotification, OnRunEffects, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { EMPTY, Observable, of } from 'rxjs';
import { catchError, exhaustMap, map, mergeMap, takeUntil } from 'rxjs/operators';

import { TargetPromotionContent } from '../../common/models/target-promotion-content';
import { TargetPromotionMetadata } from '../../common/models/target-promotion-metadata';
import { commonContentMessages } from '../../common/store/target-promotion.selectors';
import {
    loadTargetPromotionData,
    loadTargetPromotionData_Fail,
    loadTargetPromotionData_Success,
    updateNoPrize,
    updateResultsContent,
    updateTabs,
    userOptInFailedForPromotion,
    userOptInInvalidPromoIdForPromotion,
    userOptInSuccessForPromotion,
    userOptedInForPromotion,
} from '../../common/store/target-promotions.actions';
import { PlayerActivityContent } from '../models/player-activity-content';
import { PlayerActivityDetails, PlayerActivityRequest } from '../models/player-activity-details';
import { PlayerActivityMetaData } from '../models/player-activity-metadata';
import { PlayerActivityService } from '../services/player-activity.service';
import {
    loadPlayerActivityData,
    loadPlayerActivityData_Success,
    loadPlayerActivityOptinData_Success,
    playerActivityDestroyed,
    playerActivityInit,
} from './player-activity.actions';

@Injectable()
export class PlayerActivityEffects implements OnRunEffects {
    loadPlayerActivityData$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(loadPlayerActivityData.type),
            mergeMap((request: PlayerActivityRequest) =>
                this.playerActivityService.getPlayerActivityDetails(request).pipe(
                    map((playerActivityDetails: PlayerActivityDetails) => {
                        this.updateSinglePrizeResultsContent(
                            playerActivityDetails.playerActivityContent,
                            playerActivityDetails.playerActivityMetaData,
                            playerActivityDetails.playerActivityContent.targetPromotionCommonContent.messages ?? {},
                        );
                        return playerActivityDetails;
                    }),
                    map((playerActivityDetails: PlayerActivityDetails) =>
                        loadTargetPromotionData_Success({
                            content: playerActivityDetails.playerActivityContent,
                            metadata: playerActivityDetails.playerActivityMetaData,
                        }),
                    ),
                    catchError(() => {
                        if (request?.isAutoOptin) {
                            userOptInInvalidPromoIdForPromotion();
                            this.trackingModelService.submitTracking({
                                CategoryEvent: 'promo hub',
                                LabelEvent: 'opt in',
                                ActionEvent: 'error',
                                PositionEvent: request.promoId,
                                EventDetails: 'promotion either expired/invalid',
                                LocationEvent: 'offer page',
                            });
                        }
                        return of(loadTargetPromotionData_Fail());
                    }),
                ),
            ),
        );
    });

    loadPlayerActivityData_Success$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(loadTargetPromotionData_Success),
            map((action: { metadata: TargetPromotionMetadata; content: TargetPromotionContent }) => {
                const details = new PlayerActivityDetails();
                details.playerActivityMetaData = action.metadata as PlayerActivityMetaData;
                details.playerActivityContent = action.content as PlayerActivityContent;
                return details;
            }),
            map((response) => loadPlayerActivityData_Success(response)),
        );
    });

    loadOptinPlayerActivityData$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(userOptedInForPromotion.type),

            map((action: { promoId: string }) => action.promoId),
            concatLatestFrom(() => this.store.select(commonContentMessages)),
            mergeMap(([promoId, commonContentMessages]) =>
                this.playerActivityService.optedIn(promoId).pipe(
                    map((playerActivityDetails: PlayerActivityDetails) => {
                        this.updateSinglePrizeResultsContent(
                            playerActivityDetails.playerActivityContent,
                            playerActivityDetails.playerActivityMetaData,
                            commonContentMessages ?? {},
                        );
                        return playerActivityDetails;
                    }),
                    map((response: PlayerActivityDetails) => loadPlayerActivityOptinData_Success(response)),
                    catchError(() => {
                        of(userOptInFailedForPromotion());
                        return EMPTY;
                    }),
                ),
            ),
        );
    });

    loadPlayerActivityData_SuccessOnOptin$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(loadPlayerActivityOptinData_Success),
            map((playerActivityDetails) => loadPlayerActivityData_Success(playerActivityDetails)),
        );
    });

    userOptInSuccessForPromotion$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(loadPlayerActivityOptinData_Success),
            map((playerActivityDetails) =>
                userOptInSuccessForPromotion({
                    optinDetails: playerActivityDetails.playerActivityMetaData.optinDetails,
                    optinContent: playerActivityDetails.playerActivityContent.optinContent,
                }),
            ),
        );
    });

    updateTabs$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(loadPlayerActivityOptinData_Success),
            map((playerActivityDetails) => updateTabs({ tabs: playerActivityDetails.playerActivityContent.tabs })),
        );
    });

    updateNoPrize$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(loadPlayerActivityOptinData_Success),
            map((playerActivityDetails) => updateNoPrize({ noPrizeContent: playerActivityDetails.playerActivityContent.noPrizeContent })),
        );
    });

    updateResultsContent$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(loadPlayerActivityOptinData_Success),
            map((response) => updateResultsContent({ resultsContent: response.playerActivityContent.resultsContent })),
        );
    });

    loadTargetPromotionData$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(loadTargetPromotionData.type),
            map((action: { promoId: string }) => action.promoId),
            mergeMap((promoId) =>
                this.playerActivityService.getPlayerActivityDetails(new PlayerActivityRequest(promoId, '')).pipe(
                    map((response: PlayerActivityDetails) =>
                        loadTargetPromotionData_Success({
                            metadata: response.playerActivityMetaData as TargetPromotionMetadata,
                            content: response.playerActivityContent as TargetPromotionContent,
                        }),
                    ),
                    catchError(() => of(loadTargetPromotionData_Fail())),
                ),
            ),
        );
    });

    ngrxOnRunEffects(resolvedEffects$: Observable<EffectNotification>) {
        return this.actions$.pipe(
            ofType(playerActivityInit.type),
            exhaustMap(() => resolvedEffects$.pipe(takeUntil(this.actions$.pipe(ofType(playerActivityDestroyed.type))))),
        );
    }

    constructor(
        private actions$: Actions,
        private playerActivityService: PlayerActivityService,
        private trackingModelService: TrackingModelService,
        private store: Store,
    ) {}

    private updateSinglePrizeResultsContent(
        playerActivityContent: PlayerActivityContent,
        playerActivityMetaData: PlayerActivityMetaData,
        commonContentMessages: { [key: string]: string },
    ): void {
        const resultsContent = playerActivityContent.resultsContent;
        const tabs = playerActivityContent.tabs;

        if (tabs?.length > 0 && resultsContent !== undefined && commonContentMessages !== undefined && tabs.includes('myprogress')) {
            const areAllSlabsCompleted = playerActivityContent.myProgress?.areAllSlabsCompleted;
            resultsContent.currentlyAchievedText = areAllSlabsCompleted ? commonContentMessages.welldone : commonContentMessages.currentlyachieved;

            const playerPromoConfiguration = playerActivityMetaData.playerPromoConfiguration;
            if (playerPromoConfiguration !== undefined) {
                resultsContent.earnBetterPrize = !areAllSlabsCompleted
                    ? commonContentMessages[`${playerPromoConfiguration.criteria?.criteriaType?.toLocaleLowerCase()}_betterprize`]
                    : '';
            }
        }
    }
}
