import { Injectable } from '@angular/core';

import { OptInTrackService } from '@frontend/promo-homewidget';
import { ToastrQueueService } from '@frontend/vanilla/core';
import { Actions, EffectNotification, OnRunEffects, createEffect, ofType } from '@ngrx/effects';
import { EMPTY, Observable, of } from 'rxjs';
import { catchError, exhaustMap, map, mergeMap, takeUntil, tap } from 'rxjs/operators';

import { WebAnalyticModel } from '../../../../common/web-tracking/web-analytic.model';
import { CommonEventService } from '../../common/events/common-event.service';
import { loadTargetPromotionData_Fail, loadTargetPromotionData_Success } from '../../common/store/target-promotions.actions';
import { EdsDetails } from '../eds-models/eds-details';
import { EdsRequest } from '../eds-models/eds-request';
import { EdsService } from '../eds-service/eds.service';
import { edsDestroyed, edsInit, loadEdsData, loadEdsData_Success } from './eds.action';

@Injectable()
export class EdsEffects implements OnRunEffects {
    loadEdsData$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(loadEdsData.type),
            map((action: EdsRequest) => action),
            mergeMap((request) => {
                if (request.requestType === 'optin') {
                    return this.edsService.getEdsDetails(request).pipe(
                        tap(() => {
                            this.toastrQueueService.clear();
                            this.toastrQueueService.add('PromoOptinSuccess');
                            this.commonEventService.getWAModel().subscribe((wa) => {
                                this.webAnalyticModel = wa;
                            });
                            this.optInTrackService.trackSuccessMessage(this.webAnalyticModel);
                        }),
                        map((response: EdsDetails) => loadEdsData_Success(response)),
                        catchError(() => {
                            of(this.toastrQueueService.add('PromoOptinFail'));
                            return EMPTY;
                        }),
                    );
                } else
                    return this.edsService.getEdsDetails(request).pipe(
                        map((response: EdsDetails) => loadEdsData_Success(response)),
                        catchError(() => of(loadTargetPromotionData_Fail())),
                    );
            }),
        );
    });

    loadTargetPromotionData_Success$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(loadEdsData_Success),
            map((response) =>
                loadTargetPromotionData_Success({
                    metadata: response?.edsMetadata,
                    content: response?.edsContent,
                }),
            ),
        );
    });

    ngrxOnRunEffects(resolvedEffects$: Observable<EffectNotification>) {
        return this.actions$.pipe(
            ofType(edsInit.type),
            exhaustMap(() => resolvedEffects$.pipe(takeUntil(this.actions$.pipe(ofType(edsDestroyed.type))))),
        );
    }

    webAnalyticModel: WebAnalyticModel = new WebAnalyticModel();

    constructor(
        private actions$: Actions,
        private toastrQueueService: ToastrQueueService,
        private edsService: EdsService,
        private optInTrackService: OptInTrackService,
        private commonEventService: CommonEventService,
    ) {}
}
