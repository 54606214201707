import { Injectable } from '@angular/core';

import { RegulatoryApiService } from '@frontend/promo-homewidget';
import { ApiService } from '@frontend/promo-utils';
import { Observable } from 'rxjs';

import { CardRushDetails, CardRushRequest } from '../card-rush-models/card-rush-details';

@Injectable({
    providedIn: 'root',
})
export class CardRushService {
    constructor(
        private apiService: ApiService,
        private regulatoryApiService: RegulatoryApiService,
    ) {}

    getCardRushDetails(cardRushRequest: CardRushRequest) {
        const cardRush$ = this.apiService.get('cardrush', null, {
            params: cardRushRequest,
            showSpinner: true,
        });
        return this.regulatoryApiService.fetchDataWithReasonCode(cardRush$);
    }

    getCardRushRankingDetails(cardRushRequest: CardRushRequest, showSpinner: boolean = true) {
        return this.apiService.get('cardrush/ranking', null, {
            params: cardRushRequest,
            showSpinner: showSpinner,
        });
    }

    getCardRushRevealCardPrize(cardRushRequest: CardRushRequest) {
        return this.apiService.get('cardrush/reveal', null, {
            params: cardRushRequest,
            showSpinner: true,
        });
    }

    optedIn(promoId: string): Observable<CardRushDetails> {
        const request = new CardRushRequest(promoId, 'optin');

        return this.apiService.get('cardrush/optin', null, {
            params: request,
        });
    }
}
