import { Routes } from '@angular/router';

import { VANILLA_LAZY_ROUTES } from '@frontend/loaders/routes';

import { PROMO_ROUTES } from './promo.routes';

export const appRoutes: Routes = [
    {
        path: '{culture}',
        children: [
            ...VANILLA_LAZY_ROUTES,
            {
                path: 'promo',
                children: [...PROMO_ROUTES],
            },
            {
                path: '**',
                loadChildren: () => import('@frontend/vanilla/features/not-found').then((m) => m.ROUTES),
            },
        ],
    },
    {
        path: '**',
        loadChildren: () => import('@frontend/vanilla/features/not-found').then((m) => m.ROUTES),
    },
];
