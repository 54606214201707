import { Action, createReducer, on } from '@ngrx/store';

import { EdsDetails } from '../eds-models/eds-details';
import { EdsMetaData } from '../eds-models/eds-metadata';
import { edsDestroyed, loadEdsData_Success } from './eds.action';

export const initialState: EdsDetails = new EdsDetails();

export class EdsAction implements Action {
    type: string;
    edsMetadata: EdsMetaData;
}

export const edsReducer = createReducer(
    initialState,
    on(loadEdsData_Success, (state, action) => ({
        ...state,
        ...action,
    })),
    on(edsDestroyed, () => initialState),
);
