import { createReducer, on } from '@ngrx/store';

import { LeaderboardRankingStoreModel } from '../../models/ranking/ranking-models';
import {
    leaderboardRankingDestroyed,
    loadLeaderBoardRankingData_Success,
    loadLeaderboardRankingScrollDownData,
    loadLeaderboardRankingScrollUpData,
} from './leaderboard-ranking.actions';

export const initialState: LeaderboardRankingStoreModel = new LeaderboardRankingStoreModel();

export const leaderboardRankingReducer = createReducer(
    initialState,
    on(loadLeaderBoardRankingData_Success, (state, action) => ({
        ...state,
        ...action,
    })),
    on(loadLeaderboardRankingScrollUpData, (state, action) => ({
        ...state,
        leaderboardContent: {
            ...state.leaderboardContent,
        },
        leaderboardMetaData: {
            ...state.leaderboardMetaData,
            leaderBoardDetails: [...action.leaderboardDetails, ...state.leaderboardMetaData.leaderBoardDetails],
        },
    })),
    on(loadLeaderboardRankingScrollDownData, (state, action) => ({
        ...state,
        leaderboardContent: {
            ...state.leaderboardContent,
        },
        leaderboardMetaData: {
            ...state.leaderboardMetaData,
            leaderBoardDetails: [...state.leaderboardMetaData.leaderBoardDetails, ...action.leaderboardDetails],
        },
    })),
    on(leaderboardRankingDestroyed, () => initialState),
);
