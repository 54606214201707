import { Injectable } from '@angular/core';

import { TrackingModelService } from '@frontend/promo-homewidget';
import { Actions, EffectNotification, OnRunEffects, createEffect, ofType } from '@ngrx/effects';
import { EMPTY, Observable, of } from 'rxjs';
import { catchError, exhaustMap, map, mergeMap, retry, takeUntil } from 'rxjs/operators';

import { LeaderboardRankingStoreModel, PlayerRankDetails } from '../../common/models/ranking/ranking-models';
import { TargetPromotionContent } from '../../common/models/target-promotion-content';
import { TargetPromotionMetadata } from '../../common/models/target-promotion-metadata';
import {
    getLeaderboardRankingScrollDownData,
    getLeaderboardRankingScrollUpData,
    loadLeaderBoardRankingData_Success,
    loadLeaderboardRankingScrollDownData,
    loadLeaderboardRankingScrollUpData,
} from '../../common/ranking-common/store/leaderboard-ranking.actions';
import {
    loadTargetPromotionData,
    loadTargetPromotionData_Fail,
    loadTargetPromotionData_Success,
    updateTabs,
    userOptInFailedForPromotion,
    userOptInInvalidPromoIdForPromotion,
    userOptInSuccessForPromotion,
    userOptedInForPromotion,
} from '../../common/store/target-promotions.actions';
import { LeaderboardContent } from '../models/leaderboard-content';
import { LeaderBoardDetails, LeaderBoardRequest } from '../models/leaderboard-details';
import { LeaderBoardMetaData } from '../models/leaderboard-metadata';
import { LeaderboardService } from '../service/leaderboard.service';
import { leaderBoardOptinSuccess, leaderboardDestroyed, leaderboardInit, loadLeaderBoardData } from './leaderboard.actions';

@Injectable()
export class LeaderBoardEffects implements OnRunEffects {
    loadLeaderBoardData$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(loadLeaderBoardData.type),
            map((action: LeaderBoardRequest) => action),
            mergeMap((request) =>
                this.leaderBoardService.getLeaderBoardDetails(request).pipe(
                    map((response: LeaderBoardDetails) =>
                        loadTargetPromotionData_Success({
                            metadata: response.leaderBoardMetadata,
                            content: response.leaderBoardContent,
                        }),
                    ),
                    catchError(() => {
                        if (request?.isAutoOptin) {
                            userOptInInvalidPromoIdForPromotion();
                            this.trackingModelService.submitTracking({
                                CategoryEvent: 'promo hub',
                                LabelEvent: 'opt in',
                                ActionEvent: 'error',
                                PositionEvent: request.promoId,
                                EventDetails: 'promotion either expired/invalid',
                                LocationEvent: 'offer page',
                            });
                        }
                        return of(loadTargetPromotionData_Fail());
                    }),
                ),
            ),
        );
    });

    loadLeaderBoardRankingData_Success$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(loadTargetPromotionData_Success),
            map((action: { metadata: TargetPromotionMetadata; content: TargetPromotionContent }) => {
                const leaderBoardDetails = new LeaderBoardDetails();
                leaderBoardDetails.leaderBoardMetadata = action.metadata as LeaderBoardMetaData;
                leaderBoardDetails.leaderBoardContent = action.content as LeaderboardContent;
                return leaderBoardDetails;
            }),
            map((response) => loadLeaderBoardRankingData_Success(this.getLeaderboardRankingStateModel(response))),
        );
    });

    loadOptinLeaderBoardData$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(userOptedInForPromotion.type),
            map((action: { promoId: string }) => action.promoId),
            mergeMap((promoId) =>
                this.leaderBoardService.optedIn(promoId).pipe(
                    map((response: LeaderBoardDetails) => leaderBoardOptinSuccess(response)),
                    catchError(() => {
                        of(userOptInFailedForPromotion());
                        return EMPTY;
                    }),
                ),
            ),
        );
    });

    loadLeaderBoardRankingData_SuccessOnOptin$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(leaderBoardOptinSuccess),
            map((response) => loadLeaderBoardRankingData_Success(this.getLeaderboardRankingStateModel(response))),
        );
    });

    userOptInSuccessForPromotion$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(leaderBoardOptinSuccess),
            map((details) =>
                userOptInSuccessForPromotion({
                    optinDetails: details.leaderBoardMetadata.optinDetails,
                    optinContent: details.leaderBoardContent.optinContent,
                }),
            ),
        );
    });

    updateTabs$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(leaderBoardOptinSuccess),
            map((details) => updateTabs({ tabs: details.leaderBoardContent.tabs })),
        );
    });

    loadTargetPromotionData$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(loadTargetPromotionData.type),
            map((action: { promoId: string }) => {
                const request = new LeaderBoardRequest();
                request.promoId = action.promoId;
                return request;
            }),
            mergeMap((request) =>
                this.leaderBoardService.getLeaderBoardDetails(request).pipe(
                    map((response: LeaderBoardDetails) =>
                        loadTargetPromotionData_Success({
                            metadata: response.leaderBoardMetadata,
                            content: response.leaderBoardContent,
                        }),
                    ),
                    catchError(() => of(loadTargetPromotionData_Fail())),
                ),
            ),
        );
    });

    loadLeaderboardScrollUpRankingData$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(getLeaderboardRankingScrollUpData.type),
            mergeMap((request: { leaderBoardRequest: LeaderBoardRequest; showSpinner: boolean }) =>
                this.leaderBoardService.getLeaderBoardRankingDetails(request.leaderBoardRequest, request.showSpinner).pipe(
                    map((leaderboardDetails: PlayerRankDetails[]) =>
                        loadLeaderboardRankingScrollUpData({
                            leaderboardDetails: leaderboardDetails,
                        }),
                    ),
                    catchError(() => of(loadTargetPromotionData_Fail())),
                ),
            ),
            retry(2),
        );
    });

    loadLeaderboardScrollDownRankingData$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(getLeaderboardRankingScrollDownData.type),
            mergeMap((request: { leaderBoardRequest: LeaderBoardRequest; showSpinner: boolean }) =>
                this.leaderBoardService.getLeaderBoardRankingDetails(request.leaderBoardRequest, request.showSpinner).pipe(
                    map((leaderboardDetails: PlayerRankDetails[]) =>
                        loadLeaderboardRankingScrollDownData({
                            leaderboardDetails: leaderboardDetails,
                        }),
                    ),
                    catchError(() => of(loadTargetPromotionData_Fail())),
                ),
            ),
            retry(2),
        );
    });

    ngrxOnRunEffects(resolvedEffects$: Observable<EffectNotification>) {
        return this.actions$.pipe(
            ofType(leaderboardInit.type),
            exhaustMap(() => resolvedEffects$.pipe(takeUntil(this.actions$.pipe(ofType(leaderboardDestroyed.type))))),
        );
    }

    constructor(
        private actions$: Actions,
        private leaderBoardService: LeaderboardService,
        private trackingModelService: TrackingModelService,
    ) {}

    private getLeaderboardRankingStateModel(response: LeaderBoardDetails): LeaderboardRankingStoreModel {
        return {
            leaderboardContent: {
                rankingImageContent: response.leaderBoardContent.rankingImageContent,
            },
            leaderboardMetaData: {
                leaderBoardDetails: response.leaderBoardMetadata.leaderBoardDetails,
                currentPlayerRankDetails: response.leaderBoardMetadata.currentPlayerRankDetails,
                playerCount: response.leaderBoardMetadata.playerCount,
            },
        };
    }
}
