import { createReducer, on } from '@ngrx/store';

import { PlayerActivityDetails } from '../models/player-activity-details';
import { PlayerActivityDetailsStoreModel } from '../models/player-activity-store-model';
import { loadPlayerActivityData_Success, playerActivityDestroyed } from './player-activity.actions';

export const initialState: PlayerActivityDetailsStoreModel = new PlayerActivityDetailsStoreModel();

export const playerActivityReducer = createReducer(
    initialState,
    on(loadPlayerActivityData_Success, (state, action) => ({
        ...state,
        ...getPlayerActivityDetailsStoreModel(action),
    })),
    on(playerActivityDestroyed, () => initialState),
);

function getPlayerActivityDetailsStoreModel(response: PlayerActivityDetails): PlayerActivityDetailsStoreModel {
    const playerActivityContent = response.playerActivityContent;
    return {
        playerActivityContent: {
            myProgress: playerActivityContent.myProgress,
            preRequisite: playerActivityContent.preRequisite,
            totalWagered: playerActivityContent.totalWagered,
        },
        playerActivityMetaData: {
            playerPromoConfiguration: response.playerActivityMetaData.playerPromoConfiguration,
        },
    };
}
