import { Injectable } from '@angular/core';

import { RegulatoryApiService } from '@frontend/promo-homewidget';
import { ApiService } from '@frontend/promo-utils';
import { Observable } from 'rxjs';

import { PlayerRankDetails } from '../../common/models/ranking/ranking-models';
import { LeaderBoardDetails, LeaderBoardRequest } from '../models/leaderboard-details';

@Injectable({
    providedIn: 'root',
})
export class LeaderboardService {
    constructor(
        private apiService: ApiService,
        private regulatoryApiService: RegulatoryApiService,
    ) {}

    getLeaderBoardDetails(leaderBoardRequest: LeaderBoardRequest): Observable<LeaderBoardDetails> {
        const leaderboard$ = this.apiService.get('leaderboard', null, {
            params: leaderBoardRequest,
            showSpinner: true,
        });
        return this.regulatoryApiService.fetchDataWithReasonCode(leaderboard$);
    }

    getLeaderBoardRankingDetails(leaderBoardRequest: LeaderBoardRequest, showSpinner: boolean = true): Observable<Array<PlayerRankDetails>> {
        return this.apiService.get('leaderboard/ranking', null, {
            params: leaderBoardRequest,
            showSpinner: showSpinner,
        });
    }

    optedIn(id: string): Observable<LeaderBoardDetails> {
        return this.apiService.get('leaderboard/optin', { promoId: id });
    }
}
