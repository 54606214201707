import { Injectable } from '@angular/core';

import { RegulatoryApiService } from '@frontend/promo-homewidget';
import { ApiService } from '@frontend/promo-utils';
import { Observable } from 'rxjs';

import { PlayerActivityDetails, PlayerActivityRequest } from '../models/player-activity-details';

@Injectable({
    providedIn: 'root',
})
export class PlayerActivityService {
    constructor(
        private apiService: ApiService,
        private regulatoryApiService: RegulatoryApiService,
    ) {}

    getPlayerActivityDetails(playerActivityRequest: PlayerActivityRequest) {
        const playerActivity$ = this.apiService.get('playeractivity', null, {
            params: playerActivityRequest,
            showSpinner: true,
        });
        return this.regulatoryApiService.fetchDataWithReasonCode(playerActivity$);
    }

    optedIn(promoId: string): Observable<PlayerActivityDetails> {
        return this.apiService.get('playeractivity/optin', null, {
            params: { promoId },
        }) as Observable<PlayerActivityDetails>;
    }
}
